<template>
  <div>
    <!-- BUTTONS -->
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="cancel"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="save"
      :save_loading="save_loading"
      :btnDelete="true"
      :textDelete="$t('GENERAL.DELETE')"
      v-on:delete="isShowDialogDelete = true"
      :btnDuplicate="false"
      :duplicate_loading="duplicate_loading"
      :textDuplicate="$t('GENERAL.DUPLICATE')"
      v-on:duplicate="duplicate"
    />
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card class="mb-2">
        <v-card-text>
          <Form :info.sync="formData" :edit="true" />
        </v-card-text>
      </v-card>
    </v-form>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card class="pt-3">
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="deleteItem">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Form from "./components/Form";
import { mapGetters } from "vuex";
import {
  STORE_COUPON,
  UPDATE_COUPON,
  DELETE_COUPON,
} from "@/core/services/store/general/coupons.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Buttons from "@/view/content/components/Buttons";
export default {
  components: {
    Form,
    Buttons,
  },
  data() {
    return {
      formData: null,
      loading_save: false,
      valid: true,
      save_loading: false,
      isShowDialogDelete: false,
      duplicate_loading: false,
    };
  },
  methods: {
    /**
     * return campus page
     * @method cancel
     */
    cancel() {
      this.$router.push("/admin/coupons");
    },

    /**
     * Delete Market by id
     * @method deleteItem
     */
    deleteItem() {
      this.$store.dispatch(DELETE_COUPON, this.$route.params.id);
      this.isShowDialogDelete = false;
      this.$router.push("/admin/coupons");
    },

    /**
     * updated Coupon by id
     * @method save
     * @returns 'save' object whit data
     */
    save() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.save_loading = true;

        const data = {
          couponId: this.$route.params.id,
          ...this.formData,
        };

        const id_courses = [];
        data.courses?.forEach(course => {
          id_courses.push(course.id);
        });

        data.courses = id_courses.join();
        data.categories = data.categories.join();

        this.$store
          .dispatch(UPDATE_COUPON, data)
          .then(() => {
            this.save_loading = false;

            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
          })
          .catch(() => {
            this.save_loading = false;
          });
      }
    },

    /**
     * Duplicated the coupon seleted
     * @method duplicate
     * @returns 'coupon' object whit the new data
     */
    duplicate() {
      this.duplicate_loading = true;

      const data = {
        ...this.coupon,
      };

      const id_courses = [];
      data.courses?.forEach(course => {
        id_courses.push(course.id);
      });

      data.courses = id_courses.join();

      this.$store.dispatch(STORE_COUPON, data).then(() => {
        this.duplicate_loading = false;

        this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
          variant: "success",
          solid: true,
        });
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("COUPONS.TITLE"),
        route: "/admin/coupons",
      },
      { title: this.$t("GENERAL.EDIT") },
    ]);
  },
  computed: {
    ...mapGetters(["Coupon"]),
  },
};
</script>
