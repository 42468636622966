<template>
  <div>
    <b-row>
      <!-- KEY -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-text-field
          v-model="info.key"
          v-if="edit"
          :label="$t('COUPONS.KEY')"
          outlined
          dense
          disabled
        />
        <v-text-field
          v-model="info.key"
          :label="$t('COUPONS.KEY')"
          :rules="validations.required"
          required
          outlined
          dense
          v-else
        />
      </b-col>

      <!-- DISCOUNT_TYPE -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong class="required-label">
            {{ $t("COUPONS.DISCOUNT_TYPE") }}
          </strong>
        </h5>
      </b-col>

      <b-col cols="12">
        <v-radio-group v-model="info.typeId" row :rules="validations.required">
          <v-radio :label="$t('COUPONS.PERCENTAGE')" :value="1" />
          <v-radio :label="$t('COUPONS.PERMANENT')" :value="2" />
        </v-radio-group>
      </b-col>

      <!-- DISCOUNT -->
      <b-col lg="4" md="4" sm="12" cols="12">
        <v-text-field
          v-model="info.discount"
          :label="$t('COUPONS.DISCOUNT')"
          :rules="validations.required"
          required
          outlined
          dense
        />
      </b-col>

      <!-- DATE_START -->
      <b-col lg="4" md="4" sm="12" cols="12">
        <v-menu transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="info.start_date"
              :label="$t('COUPONS.DATE_START')"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="info.start_date"
            @change="startDate"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </b-col>

      <!-- DATE_END -->
      <b-col lg="4" md="4" sm="12" cols="12">
        <v-menu transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="info.end_date"
              :label="$t('COUPONS.DATE_END')"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="info.end_date"
            @change="endDate"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </b-col>

      <!-- DATE_START_COURSE -->
      <b-col lg="3" md="3" sm="12" cols="12">
        <v-menu transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="info.start_date_course"
              :label="$t('COUPONS.DATE_START_COURSE')"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="info.start_date_course"
            @change="courseStartDate"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </b-col>

      <!-- DATE_START_END -->
      <b-col lg="3" md="3" sm="12" cols="12">
        <v-menu transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="info.end_date_course"
              :label="$t('COUPONS.DATE_START_END')"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="info.end_date_course"
            @change="courseEndDate"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </b-col>

      <!-- AMOUNT_MINIMUM -->
      <b-col lg="3" md="3" sm="12" cols="12">
        <v-text-field
          v-model="info.min_amount"
          :label="$t('COUPONS.AMOUNT_MINIMUM')"
          :rules="validations.noNegative"
          outlined
          dense
        />
      </b-col>

      <!-- LIMIT -->
      <b-col lg="3" md="3" sm="12" cols="12">
        <v-text-field
          v-model="info.limit"
          :rules="validations.noNegative"
          :label="$t('COUPONS.LIMIT')"
          outlined
          dense
        />
      </b-col>

      <!-- COURSE CATEGORY -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-autocomplete
          v-model="info.categories"
          :items="categories"
          :label="$t('COUPONS.COURSE_CATEGORY')"
          item-text="name"
          item-value="categoryId"
          multiple
          deletable-chips
          small-chips
          dense
          outlined
        />
      </b-col>

      <!-- MIN WEEK -->
      <b-col lg="3" md="3" sm="12" cols="12">
        <v-text-field
          v-model="info.min_amount_qty"
          :rules="validations.noNegative"
          :label="$t('COUPONS.MIN_WEAK')"
          outlined
          dense
        />
      </b-col>

      <!-- MIN LEASON -->
      <b-col lg="3" md="3" sm="12" cols="12">
        <v-text-field
          v-model="info.min_intensity"
          :rules="validations.noNegative"
          :label="$t('COUPONS.MIN_LEASON')"
          outlined
          dense
        />
      </b-col>

      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>{{ $t("COUPONS.TITLE2") }}</strong>
        </h5>
      </b-col>
    </b-row>
    <!-- FIND COURSES -->
    <v-form ref="form" class="w-100">
      <b-row>
        <!-- SELECT TYPE TO SEARCH -->
        <b-col lg="2" md="2" sm="12" cols="12">
          <v-select
            v-model="infoSearch.option"
            :rules="[validations.requiredCoupons(edit)]"
            :items="optionSearchCourse"
            :label="$t('COUPONS.SEARCH_BY')"
            item-text="text"
            item-value="value"
            :required="!edit"
            dense
            outlined
            @change="resetForm"
          />
        </b-col>

        <!-- TEXT -->
        <b-col lg="4" md="4" sm="12" cols="12">
          <v-text-field
            v-model="infoSearch.search"
            :rules="[
              validations.search(infoSearch.search, infoSearch.option, edit),
            ]"
            :label="$t('COUPONS.TEXT')"
            clearable="true"
            :counter="infoSearch.option != ID"
            :required="!edit"
            outlined
            dense
          />
        </b-col>

        <!-- BTN -->
        <b-col lg="3" md="3" sm="12" cols="12">
          <v-btn
            depressed
            color="primary"
            :loading="loading"
            @click="searchCourses"
            :disabled="!disableSearch"
          >
            {{ $t("COUPONS.SEARCH") }}
          </v-btn>
        </b-col>
      </b-row>
    </v-form>
    <!-- Results -->
    <b-row>
      <v-col
        cols="12"
        md="4"
        v-for="(course, index) in info.listresponseCourses"
        :key="index"
        class="custom-checkbox"
      >
        <v-checkbox
          v-model="info.responseCourses"
          :label="course.nameCourse"
          :value="course.productId"
          hide-details
        />
      </v-col>

      <!-- add Course -->
      <b-col
        lg="12"
        md="12"
        sm="12"
        cols="12"
        v-if="info.listresponseCourses[0]"
      >
        <v-btn
          depressed
          color="primary"
          class="float-right"
          :loading="loading_add"
          @click="addCourses"
        >
          {{ $t("GENERAL.SELECT") }}
        </v-btn>
      </b-col>

      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>{{ $t("COUPONS.TITLE3") }}</strong>
        </h5>
      </b-col>

      <!-- COURSES -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <ProductsTreeView
          :input.sync="info.courses"
          :selection="info.courses"
          :data="info.listCourses"
          name=""
          key-id="id"
          key-name="name"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Rules from "@/core/services/utils/validations";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";
import { GET_ADMIN_COURSES } from "@/core/services/store/productsAddons/course.module";
import { GET_COUPON } from "@/core/services/store/general/coupons.module";
import { mapGetters } from "vuex";
export default {
  components: {
    ProductsTreeView,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ID: "1",
      form: {
        key: null,
        typeId: null,
        discount: null,
        start_date: null,
        end_date: null,
        start_date_course: null,
        end_date_course: null,
        min_amount: null,
        limit: null,
        campuses: null,
        categories: [],
        min_amount_qty: null,
        min_intensity: null,
        courses: [],
        responseCourses: [],
        listCourses: [],
        listresponseCourses: [],
      },
      formSearch: {
        option: null,
        search: null,
      },
      loading: false,
      loading_add: false,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
      // LIST
      optionSearchCourse: [
        { value: "1", text: "ID" },
        { value: "2", text: this.$t("COUPONS.COURSE_NAME") },
      ],
    };
  },
  created() {
    if (this.edit) {
      this.$store.dispatch(GET_COUPON, this.$route.params.id).then(data => {
        this.form.key = data.key;
        this.form.typeId = data.typeId;
        this.form.discount = data.discount;
        this.form.start_date = data.start_date
          ? this.$moment(data.start_date).format("YYYY-MM-DD")
          : null;

        this.form.end_date = data.end_date
          ? this.$moment(data.end_date).format("YYYY-MM-DD")
          : null;

        this.form.start_date_course = data.start_date_course
          ? this.$moment(data.start_date_course).format("YYYY-MM-DD")
          : null;

        this.form.end_date_course = data.end_date_course
          ? this.$moment(data.end_date_course).format("YYYY-MM-DD")
          : null;

        this.form.limit = data.limit;
        this.form.campuses = data.campuses;
        this.form.min_amount = data.min_amount;
        this.form.listCourses = data.products;
        this.form.courses = data.products;
        this.form.min_amount_qty = data.min_amount_qty;
        this.form.min_intensity = data.min_intensity;
        this.form.categories?.map(item => Number(item));
        const auxCategories = data.categories?.split(",");
        auxCategories?.forEach(item => {
          this.form.categories.push(Number(item));
        });
      });
    }
  },
  methods: {
    startDate(date) {
      if (this.form.end_date !== null) {
        if (Date.parse(this.form.end_date) < Date.parse(date)) {
          this.form.end_date = null;
        }
      }
    },
    endDate(date) {
      if (Date.parse(date) < Date.parse(this.form.start_date)) {
        this.form.start_date = null;
      }
    },
    courseStartDate(date) {
      if (this.form.end_date_course !== null) {
        if (Date.parse(this.form.end_date_course) < Date.parse(date)) {
          this.form.end_date_course = null;
        }
      }
    },
    courseEndDate(date) {
      if (Date.parse(date) < Date.parse(this.form.start_date_course)) {
        this.form.start_date_course = null;
      }
    },
    searchCourses() {
      this.loading = true;
      const SEARCH_BY_ID = "1";
      const auxCoursesIds = [];

      this.form.listresponseCourses?.forEach(courses => {
        auxCoursesIds.push(courses.productId);
      });

      let query =
        this.formSearch.option === SEARCH_BY_ID ? "&productId=" : "&name=";
      query += this.formSearch.search;
      this.$store
        .dispatch(GET_ADMIN_COURSES, query)
        .then(data => {
          data.data.forEach(course => {
            let index = auxCoursesIds.indexOf(course.productId);
            if (index === -1) {
              let element = {
                ...course,
                nameCourse: `(${course.productId}) ${course.name}`,
                id: course.productId,
              };
              this.form.listresponseCourses.push(element);
            }
          });

          this.loading = false;
        })
        .catch(() => {
          this.save_loading = false;
        });
    },
    addCourses() {
      this.loading_add = true;
      if (this.form.responseCourses.length > 0) {
        this.form.courses = [];
        this.form.listCourses = [];
        const auxCourses = [];
        const auxCoursesIds = [];
        this.form.courses?.forEach(course => {
          auxCourses.push(course);
        });

        this.form.courses = [];
        this.form.listCourses = [];

        this.form.responseCourses.forEach(course => {
          let infoCourse = this.form.listresponseCourses.filter(
            item => item.productId === course
          );
          auxCourses.push(infoCourse[0]);
          auxCoursesIds.push(infoCourse[0].productId);
        });

        if (this.edit) {
          this.Coupon.products?.forEach(course => {
            let position = auxCoursesIds.indexOf(course.data.productId);
            if (position === -1) {
              let data = {
                ...course.data,
                id: course.data.productId,
              };
              auxCourses.push(data);
            }
          });
        }

        this.form.courses = auxCourses;
        this.form.listCourses = auxCourses;
        this.form.coursesTemp = auxCourses;

        this.loading_add = false;
      } else {
        this.loading_add = false;

        this.$bvToast.toast(this.$t("MESSAGE.ERROR.NO_SELECT"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      }
    },
    resetForm() {
      this.formSearch.search = null;
    },
  },
  computed: {
    ...mapGetters(["categories", "Coupon"]),
    info() {
      return this.form;
    },
    infoSearch() {
      return this.formSearch;
    },
    disableSearch() {
      return this.formSearch.option && this.formSearch.search;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:info", data);
      },
      deep: true,
    },
  },
};
</script>
